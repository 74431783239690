import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { Iframe } from '../../components'

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }))

const Elearning = (
  {
    /* props */
  }
) => {
  //   const classes = useStyles()
  //   const { t } = useTranslation(
  //     'TODO: i18n JSON file name is missing in Elearning'
  //   )

  const moodleUrl = 'https://moodle-fmr4.onrender.com/'

  return (
    <>
      <Iframe src={moodleUrl} />
    </>
  )
}

Elearning.displayName = 'Elearning'
export { Elearning }
export default Elearning
