const LANGUAGES = Object.freeze({
  DEFAULT: 'hu',
  ENGLISH: 'en',
  HUNGARIAN: 'hu',
  UNKNOWN: 'unknown',
  UKRANIAN: 'ua',
})

export { LANGUAGES }
export default LANGUAGES
