import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resolveLanguageFromHostname } from 'helpers'
import Backend from 'i18next-http-backend'

const supportedLanguages = ['hu', 'en', 'ua']
const defaultLanguage = resolveLanguageFromHostname()

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    supportedLngs: supportedLanguages,
    //debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

i18n.changeLanguage(defaultLanguage)
console.debug('[i18n] Default language has been set to', defaultLanguage)

export default i18n
