import React, { useEffect } from 'react'

import { useStoreState, useStoreActions } from '../../store/store.hooks'
import { useRoom } from '../../twilio-doki/useRoom'
import { useLocalTracks } from '../../twilio-doki/useLocalTracks'
import { DokiRoom } from '../../components/VideoCall/DokiRoom'
import { useRoomState } from '../../twilio-doki/useRoomState'
import { post } from '../../services/request'
import { CircularProgress } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { Call } from '../../store/incomingRequest/incomingRequest.interface'
import { mixpanel } from '../../util/mixpanel'

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 500,
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    borderRadius: 15,
  },
  loading: {
    color: '#fff',
  },
}))

export const PatientRoom = () => {
  const classes = useStyles()
  const { twilioRoomUniqueName, twilioToken } = useStoreState(
    (state) => state.incomingRequest
  )

  const { setCall } = useStoreActions((actions) => actions.incomingRequest)

  const { localTracks, getLocalVideoTrack } = useLocalTracks({
    applyBackgroundImageToVideo: false,
  })
  const { room, connect } = useRoom(localTracks, console.error, {
    name: twilioRoomUniqueName,
  })
  const roomState = useRoomState(room)

  async function handleConnect() {
    await connect(twilioToken)
    try {
      mixpanel.track('receiverAck')
      const resp = await post<Call>('/call/receiverAck', {
        successfull: true,
        roomNumber: twilioRoomUniqueName,
      })
      // This is not that important
      setCall(resp)
    } catch (e: any) {
      console.error(e.stack)
    }
  }

  useEffect(() => {
    handleConnect()
  }, [])

  console.log({ room, state: room.state })

  if (roomState === 'connected')
    return (
      <DokiRoom
        room={room}
        localTracks={localTracks}
        getLocalVideoTrack={getLocalVideoTrack}
      />
    )
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} />
    </div>
  )
}
