import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const AudioVideoStateContext = createContext(null)

const AudioVideoContextProvider = ({ children }) => {
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState()
  const [devices, setDevices] = useState([])

  const value = useMemo(
    () => ({
      devices,
      setDevices,

      selectedVideoDeviceId,
      setSelectedVideoDeviceId,

      audioInputDevices: devices.filter(
        (device) => device.kind === 'audioinput'
      ),
      videoInputDevices: devices.filter(
        (device) => device.kind === 'videoinput'
      ),
      audioOutputDevices: devices.filter(
        (device) => device.kind === 'audiooutput'
      ),
    }),
    [devices, setDevices, selectedVideoDeviceId, setSelectedVideoDeviceId]
  )

  useEffect(() => {
    const handleWindowUnload = () => {
      sessionStorage.removeItem('microphoneAndCameraAccess')
      sessionStorage.removeItem('hasTestedAudioAndVideoDevices')
      console.log(
        '[AudioVideoContextProvider] Removed session storage items, camera test must be repeated'
      )
    }

    window.addEventListener('unload', handleWindowUnload)

    return () => window.removeEventListener('unload', handleWindowUnload)
  }, [])

  // console.log('[AudioVideoContextProvider]', {
  //   devices,
  //   selectedVideoDeviceId,
  // })

  return (
    <AudioVideoStateContext.Provider value={value}>
      {children}
    </AudioVideoStateContext.Provider>
  )
}

const useAudioVideo = () => {
  const context = useContext(AudioVideoStateContext)
  return context
}

export { AudioVideoContextProvider, useAudioVideo }
