import { useCallback, useEffect, useState } from 'react'
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video'
import * as VideoProcessors from '@twilio/video-processors'
import { useAudioVideo } from '../hooks'
import professionalRoomBackgroundImage from '../assets/img/professional-room-background.png'

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>()

  useEffect(() => {
    Video.createLocalAudioTrack().then((newTrack) => {
      setTrack(newTrack)
    })
  }, [])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return track
}

const loadBackgroundImage = (): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = professionalRoomBackgroundImage
    img.onload = () => resolve(img)

    img.onerror = (e) => {
      console.error('Failed to load video processor background image', e)
      reject(e)
    }
  })
}

export function useLocalVideoTrack({ applyBackgroundImage = false }) {
  const [track, setTrack] = useState<LocalVideoTrack>()
  const { selectedVideoDeviceId }: any = useAudioVideo()

  const getLocalVideoTrack = useCallback(() => {
    return Video.createLocalVideoTrack({
      frameRate: 24,
      height: { min: 480, ideal: 720 }, //720,
      width: { min: 640, ideal: 1280 }, //1280,*/
      name: 'camera',
      deviceId: selectedVideoDeviceId,
    }).then(async (newTrack) => {
      if (applyBackgroundImage) {
        const backgroundImage = await loadBackgroundImage()
        const virtualBackground =
          new VideoProcessors.VirtualBackgroundProcessor({
            assetsPath: '/twilio',
            backgroundImage,
            fitType: VideoProcessors.ImageFit.Fill,
            pipeline: VideoProcessors.Pipeline.Canvas2D,
          })

        await virtualBackground.loadModel()

        newTrack.addProcessor(virtualBackground)
      }

      setTrack(newTrack)
      return newTrack
    })
  }, [applyBackgroundImage])

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack()
  }, [getLocalVideoTrack])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return [track, getLocalVideoTrack] as const
}

export function useLocalTracks({ applyBackgroundImageToVideo = false }) {
  const audioTrack = useLocalAudioTrack()
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack({
    applyBackgroundImage: applyBackgroundImageToVideo,
  })

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack)[]

  return { localTracks, getLocalVideoTrack }
}
