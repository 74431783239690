import { accountsModel } from './accounts/accounts.model'
import { authModel } from './auth/auth.model'
import { profileModel } from './profile/profile.model'
import { complaintsModel } from './complaints/complaints.model'
import { layoutModel } from './layout/layout.model'
import { StoreModel } from './store.interface'
import { requestModel } from './request/request.model'
import { providerInfoModel } from './providerInfo/providerInfo.model'
import { incomingRequestModel } from './incomingRequest/incomingRequest.model'
import { tipsModel } from './tips/tip.model'
import { couponModel } from './coupon/coupon.model'
import { subscriptionModel } from './subscription/subscription.model'
import { settingsModel } from './settings/settings.model'
import { integrationModel } from './integration/integration.model'

const storeModel: StoreModel = {
  accounts: accountsModel,
  auth: authModel,
  layout: layoutModel,
  complaints: complaintsModel,
  profile: profileModel,
  request: requestModel,
  providerInfo: providerInfoModel,
  incomingRequest: incomingRequestModel,
  tips: tipsModel,
  coupon: couponModel,
  subscription: subscriptionModel,
  settings: settingsModel,
  integration: integrationModel,
}

export { storeModel }
