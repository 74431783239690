import { action, thunk } from 'easy-peasy'
import { IntegrationModel } from './integration.interface'
import * as integrationService from '../../services/integration.service'

const integrationModel: IntegrationModel = {
  myMedioSiteUrl: undefined,

  fetchIntegration: thunk(async (actions) => {
    const integration = await integrationService.fetchIntegration()
    actions.setIntegration(integration)
  }),

  setIntegration: action((state, payload) => {
    state.myMedioSiteUrl = payload.myMedioSiteUrl
  }),
}

export { integrationModel }
