import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import { useStoreState, useStoreActions } from '../../store/store.hooks'
import { MobileAppBar } from '../Mobile/MobileAppBar'
import logo from '../../assets/img/doki-logo-white.png'
import { LanguageSelector } from '../LanguageSelector'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  customAppBar: {
    color: '#fff',
    boxShadow: 'none',
    maxHeight: '100px',
    '@media print': {
      display: 'none',
    },
  },
  customToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  logo: {
    maxHeight: '50px',
    '@media print': {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  menuIcon: {
    color: '#fff',
  },
}))

const AppNavigation = () => {
  const classes = useStyles()
  const { setIsSideMenuOpen } = useStoreActions((actions) => actions.layout)
  const {
    layout: { isMobile },
    auth: { token },
  } = useStoreState((state) => state)

  const onLanguageChanged = (lng: any, url: any) => {
    if (url) {
      window.location.href = url
    }
  }

  if (isMobile) {
    return <MobileAppBar />
  }

  return (
    <div className={[classes.root, classes.customAppBar].join(' ')}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar className={classes.customToolBar}>
          <img src={logo} className={classes.logo} alt="doki-logo" />
          <div>
            <LanguageSelector onChange={onLanguageChanged} />
            {token && (
              <IconButton
                aria-label="open-menu"
                onClick={() => setIsSideMenuOpen(true)}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export { AppNavigation }
