import React, { useState, useEffect } from 'react'

// import { useStoreState } from '../../store/store.hooks'
import { GradientButton, WhiteButton } from '../Button'

import {
  Box,
  Grid,
  Slide,
  Collapse,
  Container,
  Typography,
} from '@material-ui/core'

import PhoneDisabled from '@material-ui/icons/PhoneDisabled'
import VideocamOff from '@material-ui/icons/VideocamOff'
import Videocam from '@material-ui/icons/Videocam'
import MicOff from '@material-ui/icons/MicOff'
import Close from '@material-ui/icons/Close'
import Mic from '@material-ui/icons/Mic'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import { VideoTrack } from '../../twilio-doki/VideoTrack/VideoTrack'
import { AudioTrack } from '../../twilio-doki/AudioTrack/AudioTrack'
import {
  AudioTrack as IAudioTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video'
import store from '../../store'

import { useStyles } from './styles'
import { IVideoTrack } from '../../twilio-doki/types'
import _ from 'lodash'
import useLocalAudioToggle from '../../twilio-doki/useLocalAudioToggle'
import useLocalVideoToggle from '../../twilio-doki/useLocalVideoToggle'
import { CallLength } from './CallLength'
import { useStoreState } from '../../store/store.hooks'
import { post } from '../../services/request'
import { navigate } from '@reach/router'
import { PushTypes } from '../../constants/pushTypes'
import { useAbly } from '../../hooks/useAbly'
import { mixpanel } from '../../util/mixpanel'
import { useTranslation } from 'react-i18next'
import { SPECIALIZATIONS } from '../../constants'
import { DownloadAttachments } from '../'

const VideoCall = ({
  videoTrack,
  audioTrack,
  localTracks,
  getLocalVideoTrack,
  room,
}: {
  videoTrack: IVideoTrack
  audioTrack: IAudioTrack
  localTracks: (LocalAudioTrack | LocalVideoTrack)[]
  getLocalVideoTrack: () => Promise<LocalVideoTrack>
  room: Room
}) => {
  const classes = useStyles()
  const { t } = useTranslation('videoCall')
  const [showHangUpDialogue, setShowHangUpDialogue] = useState(false)
  const [showMenuBars, setShowMenuBars] = useState(true)
  const [showCollapsedDetails, setShowCollapsedDetails] = useState(false)
  const [disconnectReason, setDisconnectReason] = useState<PushTypes | null>(
    null
  )

  const [isLocalAudioEnabled, toggleAudioEnabled] =
    useLocalAudioToggle(localTracks)
  const [isLocalVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle({
    room,
    localTracks,
    getLocalVideoTrack,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMenuBars(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [showMenuBars])

  const localVideoTrack = _.find(localTracks, { kind: 'video' })

  const {
    incomingRequest: { twilioRoomUniqueName, incomingRequestContents },
    profile: { profileContents },
  } = useStoreState((state) => state)

  const disconnectAndNavigateToNextScreen = () => {
    room.disconnect()
  }

  const isPediatrics =
    incomingRequestContents?.specialization === SPECIALIZATIONS.PEDIATRICS

  useEffect(() => {
    if (room) {
      room.on('disconnected', () => {
        localTracks.forEach((t) => {
          t.stop()
          t.detach()
        })

        if (profileContents.userTypeId === 2) {
          if (!disconnectReason) {
            navigate('/patient/consultancy-end')
          } else if (disconnectReason === PushTypes.MANUAL_END_CALL) {
            navigate('/patient/rematch')
          }
        }

        if (profileContents.userTypeId === 1) {
          navigate('/professional/call-finish')
        }
      })
    }
  }, [room, localTracks, profileContents, navigate, disconnectReason])

  async function handleCallEnd() {
    await post('/call/end', {
      roomNumber: twilioRoomUniqueName,
    })
    disconnectAndNavigateToNextScreen()
  }

  const ably = useAbly()
  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)

    channel.subscribe(PushTypes.END_CALL, function (message: any) {
      console.log(message)
      mixpanel.track('Ably message', message)
      disconnectAndNavigateToNextScreen()
    })

    channel.subscribe(PushTypes.MANUAL_END_CALL, (message) => {
      mixpanel.track('Ably message', message)

      // Disconnect only if the request is the current one
      const currentRequestId = store?.getState()?.request?.requestId
      if (message.data.requestId === currentRequestId) {
        console.log('Manually ending a call', message)
        setDisconnectReason(PushTypes.MANUAL_END_CALL)
        disconnectAndNavigateToNextScreen()
      } else {
        console.warn(
          `We're trying to close request #${message.data.requestId}, but the current request is #${currentRequestId}. Ignoring message`
        )
      }
    })

    return () => {
      channel.unsubscribe(PushTypes.END_CALL)
      channel.unsubscribe(PushTypes.MANUAL_END_CALL)
    }
  }, [profileContents.id, ably])

  return (
    <div>
      <Grid
        container
        className={classes.container}
        onMouseMove={() => setShowMenuBars(true)}
        onMouseLeave={() => setShowMenuBars(false)}
      >
        <div className={classes.videoContainer}>
          <VideoTrack
            track={videoTrack as IVideoTrack}
            priority="high"
            isLocal={false}
          />
          <AudioTrack track={audioTrack as IAudioTrack} />
        </div>
        <Container maxWidth="sm" className={classes.bodyContainer}>
          <Slide
            direction="down"
            timeout={400}
            in={showMenuBars && !showHangUpDialogue}
          >
            <div className={classes.headerContainer}>
              <CallLength />
              <Typography className={classes.header}>
                {profileContents?.userTypeId === 1
                  ? `${incomingRequestContents?.requester?.name}`
                  : `${incomingRequestContents?.provider?.namePrefix || ''} ${
                      incomingRequestContents?.provider?.lastName
                    } ${incomingRequestContents?.provider?.firstName}`}
              </Typography>
            </div>
          </Slide>
          <Slide
            direction="up"
            timeout={400}
            in={showMenuBars && !showHangUpDialogue}
          >
            <div className={classes.bottomContainer}>
              <Box className={classes.buttonBlock}>
                <div
                  className={classes.roundButton}
                  onClick={() => {
                    // TODO: hide "personal" camera
                    // setIsCameraHidden(!isCameraHidden)
                    toggleVideoEnabled()
                  }}
                >
                  {isLocalVideoEnabled ? (
                    <Videocam className={classes.roundButtonIcon} />
                  ) : (
                    <VideocamOff className={classes.roundButtonIcon} />
                  )}
                </div>
                <div
                  className={classes.roundButton}
                  onClick={() => {
                    // TODO: mute "personal" microphone
                    // setIsMicrophoneMuted(!isMicrophoneMuted)
                    toggleAudioEnabled()
                  }}
                >
                  {isLocalAudioEnabled ? (
                    <Mic className={classes.roundButtonIcon} />
                  ) : (
                    <MicOff className={classes.roundButtonIcon} />
                  )}
                </div>
                <div
                  className={classes.roundButton + ' ' + classes.roundButtonRed}
                  onClick={() => setShowHangUpDialogue(true)}
                >
                  <PhoneDisabled className={classes.roundButtonIcon} />
                </div>
              </Box>
            </div>
          </Slide>
        </Container>
        <div
          className={classes.smallVideoContainer}
          onClick={() => console.log('TODO: switch participants')}
        >
          <VideoTrack
            track={localVideoTrack as IVideoTrack}
            priority="high"
            isLocal={true}
          />
        </div>
        <Slide direction="up" timeout={400} in={showHangUpDialogue}>
          <Container className={classes.dialogueContainer}>
            <Close
              className={classes.closeIcon}
              onClick={() => setShowHangUpDialogue(false)}
            />

            <Typography className={classes.dialogueText}>
              {t<string>('dialogueText')}
            </Typography>

            <div className={classes.dualButtonContainer}>
              <GradientButton
                onClick={() => setShowHangUpDialogue(false)}
                className={classes.button}
              >
                {t<string>('cancelButton')}
              </GradientButton>
              <WhiteButton onClick={handleCallEnd} className={classes.button}>
                {t<string>('hangUpButton')}
              </WhiteButton>
            </div>
          </Container>
        </Slide>
      </Grid>

      <Grid
        container
        justifyContent="center"
        className={classes.extraCloseButton}
      >
        <GradientButton
          onClick={() => setShowHangUpDialogue(true)}
          className={classes.button}
        >
          {t('extraCloseButtonText')}
        </GradientButton>
      </Grid>

      <Collapse in={showCollapsedDetails}>
        <Container className={classes.patientInfo}>
          <span className={classes.label}>{t<string>('complaintLabel')}</span>
          <span className={classes.complaints}>
            {incomingRequestContents?.complaints
              .map((complaint) => complaint.name)
              .join(', ')}
          </span>
          <span className={classes.complaintDetailText}>
            {incomingRequestContents?.description}
          </span>
          {profileContents.userTypeId === 1 && isPediatrics && (
            <>
              <span className={classes.complaintDetailText}>
                <b>{t<string>('childName')}: </b>
                {incomingRequestContents?.childName}
              </span>
              <span className={classes.complaintDetailText}>
                <b>{t<string>('childAge')}: </b>
                {incomingRequestContents?.childAge}
              </span>
            </>
          )}
          <DownloadAttachments shortenFileNames={true} />
        </Container>
      </Collapse>
      <WhiteButton
        onClick={() => setShowCollapsedDetails(!showCollapsedDetails)}
        className={classes.collapsButton}
      >
        {t<string>('detailsButton')}
        {showCollapsedDetails ? <ExpandLess /> : <ExpandMore />}
      </WhiteButton>
    </div>
  )
}

export { VideoCall }
