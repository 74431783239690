import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Link as LinkIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { formatFileName } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  fileInput: {
    borderRadius: '1rem',
    backgroundColor: 'rgba(100,115,175,0.1)',
    border: '1px dashed rgba(100,115,175,0.4)',
    color: '#6473AF',
    fontSize: '1rem',
    padding: '1rem',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      marginBottom: 10,
    },
  },
  uploadedAttachmentsHeader: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    color: '#6473AF',
  },
}))

const DownloadAttachments = ({
  alreadyAttachedFiles = [],
  shortenFileNames = false,
}) => {
  const [attachedFiles, setAttachedFiles] = useState([])

  const classes = useStyles()
  const { t } = useTranslation('requestImgDownload')

  const { incomingRequest } = useStoreState((state) => state)
  const attachments =
    (incomingRequest &&
      incomingRequest.incomingRequestContents &&
      incomingRequest.incomingRequestContents.attachments) ||
    []

  useEffect(() => {
    if (Array.isArray(alreadyAttachedFiles)) {
      //setAttachedFiles(alreadyAttachedFiles)
    }
  }, [alreadyAttachedFiles])

  const handleOpenAttachment = (attachment) => {
    if (attachment && attachment.fileUrl)
      window.open(attachment.fileUrl, '_blank')
  }

  const attachmentSource =
    Array.isArray(attachedFiles) && attachedFiles.length > 0
      ? attachedFiles
      : Array.isArray(attachments) && attachments.length > 0
      ? attachments
      : []

  const attachmentGrid = attachmentSource.map((attachment) => (
    <Grid item key={`attachment-${attachment.id}`} md={6} xs={12}>
      <label htmlFor="contained-button-file">
        <Button
          variant="raised"
          color="primary"
          component="span"
          className={classes.fileInput}
          onClick={() => handleOpenAttachment(attachment)}
        >
          {shortenFileNames
            ? formatFileName(attachment.fileName, 30)
            : attachment.fileName}
          <LinkIcon />
        </Button>
      </label>
    </Grid>
  ))

  return (
    <>
      <Typography className={classes.uploadedAttachmentsHeader}>
        {t('uploadedAttachments')}
      </Typography>
      <Grid
        container
        className={classes.uploadedAttachmentsContainer}
        spacing={1}
      >
        {attachmentGrid}
      </Grid>
    </>
  )
}

DownloadAttachments.displayName = 'DownloadAttachments'
export { DownloadAttachments }
export default DownloadAttachments
