import EventEmitter from 'events'
import { useCallback, useEffect, useRef, useState } from 'react'
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video'
import { Callback } from './types'

export function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  options?: ConnectOptions
) {
  //eslint-disable-next-line
  // @ts-ignore
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room)
  const [isConnecting, setIsConnecting] = useState(false)
  //eslint-disable-next-line
  const disconnectHandlerRef = useRef<() => void>(() => {})
  const localTracksRef = useRef<LocalTrack[]>([])

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks
  }, [localTracks])

  const connect = useCallback(
    (token: string) => {
      setIsConnecting(true)

      return Video.connect(token, {
        ...options,
        preferredVideoCodecs: ['H264'],
        tracks: [],
      }).then(
        (newRoom) => {
          setRoom(newRoom)

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            //eslint-disable-next-line
            //@ts-ignore
            setTimeout(() => setRoom(new EventEmitter() as Room))
            window.removeEventListener(
              'beforeunload',
              disconnectHandlerRef.current
            )
          })

          newRoom.localParticipant.on(
            'trackPublicationFailed',
            (error, localTrack) => {
              console.warn(
                '%cFailed to publish LocalTrack "%s": %s',
                'background-color:yellow;color:red;font-weight:bold;font-size:18px;',
                localTrack.name,
                error.message
              )
            }
          )

          newRoom.on(
            'trackSubscriptionFailed',
            (error, remoteTrackPublication, remoteParticipant) => {
              console.warn(
                '%cFailed to subscribe to Remote Track "%s" from RemoteParticipant "%s": %s',
                'background-color:yellow;color:red;font-weight:bold;font-size:18px;',
                remoteTrackPublication.trackName,
                remoteParticipant.identity,
                error.message
              )
            }
          )

          //eslint-disable-next-line
          // @ts-ignore
          window.twilioRoom = newRoom

          localTracksRef.current.forEach((track) =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, {
              priority: track.kind === 'video' ? 'low' : 'standard',
            })
          )

          disconnectHandlerRef.current = () => newRoom.disconnect()
          setIsConnecting(false)

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnectHandlerRef.current)
        },
        (error) => {
          onError(error)
          setIsConnecting(false)
        }
      )
    },
    [options, onError]
  )

  return { room, isConnecting, connect }
}
